import { constantRouterMap } from "@/router/routers";
import Layout from "@/layout/index";

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    },
  },
  actions: {
    GenerateRoutes({ commit }, asyncRouter) {
      commit("SET_ROUTERS", asyncRouter);
    },
  },
};

export const filterAsyncRouter = (routers, type = null) => {
  // 遍历后台传来的路由字符串，转换为组件对象
  return routers.filter((router) => {
    if (router.component) {
      if (router.component === "Layout" && !type) {
        // Layout组件特殊处理
        router.component = Layout;
      } else if (type && router.component != "Layout") {
        const component = router.component;
        router.meta.id = router.id;
        router.component = loadView(component);
      } else {
        router.component = () => import("@/views/features/404");
        router.path = "/404";
      }
    }
    if (router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children, "children");
    }
    return true;
  });
};

export const loadView = (view) => {
  return (resolve) => require([`@/views/${view}`], resolve);
};

export default permission;
