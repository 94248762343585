import request from "@/utils/request";

/**
 * 获取短链
 * @param {*} data
 * longUrl 原链接
 * isUpdate 1 更新短链 默认不传
 * @returns
 */
export function getSortLink(data) {
  return request({
    url: "/common-server/pc/sortServer/getSortLink",
    method: "post",
    data,
  });
}
// 获取消息通知模板
export function noticeTemplate(data) {
  return request({
    url: "/common-server/pc/notice-template",
    method: "get",
    params: data,
  });
}
//详情
export function sysConfigDetails(data) {
  return request({
    url: "/common-server/sysConfig",
    method: "get",
    params: data,
  });
}
//新增、编辑
export function sysConfig(data) {
  return request({
    url: "/common-server/sysConfig",
    method: "post",
    data,
  });
}
// 解密手机号
export function getUnprotectPhone(data) {
  return request({
    url: "/common-server/pc/authority/getUnprotectPhone",
    method: "post",
    data,
  });
}

//导入中心列表
export function getImportantList(data) {
  return request({
    url: "/common-server/pc/import/getList",
    method: "get",
    params: data,
  });
}
